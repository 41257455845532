import { render, staticRenderFns } from "./modalEditVerbasDiversas.vue?vue&type=template&id=2630556a&scoped=true&"
import script from "./modalEditVerbasDiversas.vue?vue&type=script&lang=js&"
export * from "./modalEditVerbasDiversas.vue?vue&type=script&lang=js&"
import style0 from "./modalEditVerbasDiversas.vue?vue&type=style&index=0&id=2630556a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2630556a",
  null
  
)

export default component.exports