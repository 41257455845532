import { render, staticRenderFns } from "./modalDSR.vue?vue&type=template&id=36bde853&scoped=true&"
import script from "./modalDSR.vue?vue&type=script&lang=js&"
export * from "./modalDSR.vue?vue&type=script&lang=js&"
import style0 from "./modalDSR.vue?vue&type=style&index=0&id=36bde853&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bde853",
  null
  
)

export default component.exports