import { render, staticRenderFns } from "./modalDSRBase.vue?vue&type=template&id=5000e4e4&scoped=true&"
import script from "./modalDSRBase.vue?vue&type=script&lang=js&"
export * from "./modalDSRBase.vue?vue&type=script&lang=js&"
import style0 from "./modalDSRBase.vue?vue&type=style&index=0&id=5000e4e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5000e4e4",
  null
  
)

export default component.exports